.welcome-page {
  $maxWidth: 700px;

  @include container($maxWidth);

  &__title {
    text-align: center;
    font-family: $fontHeadlineFamily;
    font-size: $fontSizeXXLarge;
    font-weight: $fontWeightSemiBold;
    margin: 0 0 24px;
    transition: font-weight 0.3s;

    &--active {
      font-weight: $fontWeightBold;
    }
  }

  &__section {
    padding: 16px 0;

    &--register {
      border-top: 1px solid $grayColor;
      padding-top: 32px;
    }
  }

  &__global-error {
    @include container($maxWidth);
  }

  &__tab-guest {
    display: block;
    text-decoration: underline;
    border: 0;
    margin: 16px auto 0;
    font-size: $fontSizeBase;
    cursor: pointer;
    padding: 8px 16px;
    background: transparent;
  }

  .login-form__group {
    max-width: none;
  }

  .button {
    width: 100%;
  }
}
