.login-form {
  &__link {
    padding: 4px 0;
    margin: 12px 0 4px;
  }

  &__buttons {
    margin: 24px 0 0;
    display: flex;
    flex-flow: column-reverse;
    text-align: center;
  }

  &__mandatory-text {
    font-size: $fontSizeXXSmall;
    display: block;

    @media (min-width: $screen-tablet-portrait) {
      margin-top: 0;
    }
  }
}
